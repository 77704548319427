// static config
// @AUTHOR CAIHUAZHI <huazhi.chz@alibaba-inc.com>
// @CREATE 2023/11/28 20:46

export const SPI_HOST = 'https://api.aidc-ai.com/rest';

export const RESERVED_KEYS = ['app_key', 'target_app_key', 'timestamp', 'sign_method', 'sign', 'secret'];

export const SIGNATURE_API = '/open/api/signature';

export const IFRAME_CLASS_NAME = 'aidc-open-frame';
